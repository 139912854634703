import React from "react"

export default ({ videoId }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "53.5%" /* 16:9 => 56,25% */,
        paddingTop: 25,
        height: 0,
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <iframe
        title={videoId}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0&modestbranding=1&showinfo=0&autohide=1&autoplay=0`}
        frameBorder="0"
      />
    </div>
  )
}
