import React from "react"

import Layout from "../components/layout"
// import ImageHeadshot from "../components/images/nicole_headshot"
import SEO from "../components/seo"
// import TextWithImage from "../components/text-with-image"
import YoutubeVideo from "../components/youtube-video"
import ExternalLink from "../components/external-link"
import HeaderCoach from "../components/header-coach"
import BodyWrapper from "../components/body-wrapper"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Coaching, Schauspiel & Seminare" />

    <HeaderCoach />
    <BodyWrapper>
      <h2>Wer bin ich?</h2>
      <p>
        Ich bin Coach, Schauspielerin und Sängerin und bringe in meinen
        Seminaren Erfahrungen aus all diesen Bereichen zusammen. Derzeit wohne
        ich mit meinem Hund Etna in Berlin. Ursprünglich komme ich aus dem
        Rheinland und bin beruflich deutschlandweit im Einsatz.
      </p>
      <YoutubeVideo videoId="FwMuQV7g39w" />
      <h2>Was mache ich?</h2>
      <p>„Ich weiß es, aber…“</p>
      <p>
        Der Kopf weiß es schon, aber die Gefühle kommen nicht hinterher. Daher
        möchte ich dir und deinen Gefühlen den Raum geben, um hinterher zu
        kommen. Da Musik wissenschaftlich erwiesenermaßen den Zugang zu
        Emotionen erleichtert nutzen wir diese. Ich biete dir ein Coaching mit
        und durch Gesang oder Musik.
      </p>
      <p>(Vorkenntnisse nicht erforderlich)</p>
      <h2>Wie machen wir das?</h2>
      <p>
        Dir fehlt manchmal der Durchblick, aber durch Musik wird es leichter?
      </p>
      <p>
        Su sprichst, singst und vor allem fühlst während deines Coaching.
        Gemeinsam erstellen wir deine Gefühlssongliste, damit in alltäglichen
        und auch schwierigen Situationen nicht nur dein Kopf Klarheit hat.
      </p>
      <p>
        60 min Zeit für dich und was du gerade brauchst. Ich möchte dich dabei
        begleiten.
      </p>

      <YoutubeVideo videoId="VE0btkVktgg" />

      <h2>Weitere Leistungen:</h2>
      <ul>
        <li>Stimmtraining</li>
        <li>Liedinterpretation</li>
        <li>Gruppenseminare</li>
      </ul>
      <p>
        Bei Interesse an meiner künstlerischen Tätigkeit schaut doch gern{" "}
        <Link to="/artist/">hier</Link>.
      </p>

      <h2>Angebot anfordern</h2>

      <p>
        Einen Termin für euer kostenfreies Erstgespräch bekommt ihr hier:{" "}
        <a href="mailto:hallo@nicolebehnke.de">hallo@nicolebehnke.de</a>
      </p>
    </BodyWrapper>
  </Layout>
)

export default IndexPage
